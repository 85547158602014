<template>
</template>
<script>
   import json from "./../json/redirect.json"
    export default {
        name: 'sabcold',
        data() {
            return {
                other:['other','hockey','athletics'],
                routeFlag : '',
                arr: [],
                myJson: json,
            }
        },
        mounted() {
            this.getNewsDetail();
        },
        methods: {
            getNewsDetail () {
                this.arr = Object.values(this.myJson);
                if(this.$route.params.slug=='tag' && this.arr.some(item => item.slug === this.$route.params.slug2)){
                    let item = this.arr.find(item => item.slug === this.$route.params.slug2);
                    this.routeFlag = item.sport;
                    if(item.sport==''){
                        this.$router.push({ name: 'home'});
                    }
                }else if(this.$route.params.slug =='category' && this.$route.params.slug2){
                    if(this.$route.params.slug2=='football' || this.$route.params.slug2=='soccer'){
                        this.routeFlag = 'football';
                    }else if(this.$route.params.slug2=='cricket'){
                        this.routeFlag = 'cricket';
                    }else if(this.$route.params.slug2=='rugby'){
                        this.routeFlag = 'rugby';
                    }else if(this.other.includes(this.$route.params.slug2)){
                        this.routeFlag = 'other';
                    }
                }else if(this.arr.some(item => item.slug === this.$route.params.slug)){
                    let item = this.arr.find(item => item.slug === this.$route.params.slug);
                    this.routeFlag = item.sport;
                }
                if(this.routeFlag && this.routeFlag !=''){
                    this.$router.push({ name: 'sport_home' , params: {sport: this.routeFlag , news: 'news'} });
                }
                else{
                    return this.$axios.get(this.$GetSportsNewsDetail + '?slug=' + this.$route.params.slug)
                    .then(response => {
                        if (response.data) {
                            if(response.data.news && response.data.news.sport_id){
                                if(response.data.news.sport_id == 1){
                                    this.$router.push({ name: 'news_detail' , params: {sport: 'football', news: 'news', slug: this.$route.params.slug} });
                                }else if(response.data.news.sport_id == 15){
                                    this.$router.push({ name: 'news_detail' , params: {sport: 'cricket', news: 'news', slug: this.$route.params.slug} });
                                }else if(response.data.news.sport_id == 22){
                                    this.$router.push({ name: 'main_news_detail' , params: {news: 'news', slug: this.$route.params.slug} });
                                }else if(response.data.news.sport_id == 34){
                                    this.$router.push({ name: 'news_detail' , params: {sport: 'rugby', news: 'news', slug: this.$route.params.slug} });
                                }else{
                                    this.$router.push({ name: 'pageNotFound' , params: {news: 'sabc', slug: this.$route.params.slug}});
                                }
                            }else{
                                this.$router.push({ name: 'home'});
                            }
                        }
                    })
                    .catch(error => {})
                }
            },
        }
    }
</script>
